<template>
  <div id="app" style="min-height: auto;">
    <router-view>

    </router-view>
    <van-tabbar v-model="active">
      <van-tabbar-item icon="home-o" :to="{ name: 'frontView' }">杂七杂八的踩坑</van-tabbar-item>
      <van-tabbar-item icon="desktop-o" :to="{ name: 'backDevView' }">后端开发</van-tabbar-item>
      <van-tabbar-item icon="edit" :to="{ name: 'AlgorithmView' }">算法</van-tabbar-item>
      <van-tabbar-item icon="manager-o" :to="{ name: 'mineView' }">我的</van-tabbar-item>
    </van-tabbar>
    <div style="width:300px;margin:0 auto; padding:50px 0;">
      <a target="_blank" href="http://www.beian.gov.cn/"
        style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
        <!-- <img src="请将备案图标链接放置此处" style="float:left;" /> -->
        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">粤ICP备2024187297号-2
          @Lysssyo
        </p>
      </a>
    </div>

  </div>
</template>


<script>
export default {
  data() {
    return {
    }
  }
}
</script>

<style></style>
