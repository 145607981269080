import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path:'/AlgorithmView',
    name:'AlgorithmView',
    component: () => import('../views/AlgorithmView.vue')
  },
  {
    path:'/backDevView',
    name:'backDevView',
    component: () => import('../views/backDevView.vue')
  },
  {
    path:'/mineView',
    name:'mineView',
    component: () => import('../views/mineView.vue')
  },
  {
    path:'/frontView',
    name:'frontView',
    component: () => import('../views/frontView.vue')
  },
  {
    path:'/markdownView',
    name:'markdownView',
    component: () => import('../views/markdownView.vue')
  },
  {
    path:'/EnterView',
    name:'EnterView',
    component: () => import('../views/EnterView.vue')
  },
  {
    path: '/',
    redirect:'/EnterView' 
  },

]

const router = new VueRouter({
  routes
})

export default router
